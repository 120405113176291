import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'm14.54 13.47-3.86-3.86a5.23 5.23 0 1 0-1.06 1.06l3.86 3.86a.75.75 0 0 0 1.06 0 .75.75 0 0 0 0-1.06Zm-11.78-7a3.73 3.73 0 1 1 3.73 3.73 3.73 3.73 0 0 1-3.73-3.72Z';
const draw24px =
  'M20.59,19.53l-5.32-5.32a6.76,6.76,0,1,0-1.06,1.06l5.32,5.32a.74.74,0,0,0,.53.22.71.71,0,0,0,.53-.22A.74.74,0,0,0,20.59,19.53ZM4.75,10A5.25,5.25,0,1,1,10,15.25,5.26,5.26,0,0,1,4.75,10Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const MagnifyingGlassIcon = forwardRef<
  SVGSVGElement,
  ComponentProps<typeof Icon>
>(generateRenderedIcon(icons, 'magnifyingGlass')) as IconWithRender;

MagnifyingGlassIcon.displayName = 'MagnifyingGlassIcon';
MagnifyingGlassIcon.propTypes = Icon.propTypes;
MagnifyingGlassIcon.renderIcon = (props) => <MagnifyingGlassIcon {...props} />;
export default MagnifyingGlassIcon;
