import styled, { css } from 'styled-components';
import { colors, margins, paddings } from './lightTheme';

export const variant = (prop, variants) => (props) => variants[props[prop]];

export const boolean = (prop, style) => (props) => props[prop] && style;

export const variantColor =
  (prop = 'variantColor', defaultValue) =>
  (props) =>
    colors[props[prop]] || props[prop] || defaultValue;

export const getMixin = (props) => {
  const { mx, my, mb, mr, ml, mt, px, py, pb, pr, pl, pt, ...others } = props || {};
  const margins = { mx, my, mb, mr, ml, mt };
  const paddings = { px, py, pb, pr, pl, pt };
  return { margins, paddings, others };
};

export const marginMixin = css`
  margin-left: ${(props) => margins[props.mx || props.ml] || props.mx || props.ml};
  margin-right: ${(props) => margins[props.mx || props.mr] || props.mx || props.mr};
  margin-top: ${(props) => margins[props.my || props.mt] || props.my || props.mt};
  margin-bottom: ${(props) => margins[props.my || props.mb] || props.my || props.mb};
`;

export const paddingMixin = css`
  padding-left: ${(props) => paddings[props.px || props.pl] || props.px || props.pl};
  padding-right: ${(props) => paddings[props.px || props.pr] || props.px || props.pr};
  padding-top: ${(props) => paddings[props.py || props.pt] || props.py || props.pt};
  padding-bottom: ${(props) => paddings[props.py || props.pb] || props.py || props.pb};
`;

export const rgba = (color, alpha) => {
  const c = colors[color] || color;
  if (!c || c.length !== 7 || c[0] !== '#') return c;

  const r = parseInt(c.slice(1, 3), 16);
  const g = parseInt(c.slice(3, 5), 16);
  const b = parseInt(c.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getHiddenInput = (Toggle) => styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);

  & + ${Toggle} {
    transition: 0.3s box-shadow cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover + ${Toggle} {
    box-shadow: 0 0 0 2px ${rgba(colors.charcoal600, 0.15)};
  }

  &:focus + ${Toggle} {
    box-shadow: 0 0 0 2px ${rgba(colors.blue, 0.35)};
  }

  &:active + ${Toggle} {
    box-shadow: 0 0 0 4px ${rgba(colors.charcoal600, 0.25)};
  }

  &[disabled] + ${Toggle} {
    cursor: not-allowed;

    &,
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
`;
