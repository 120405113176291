import borderRadius from './styles/borderRadius';
import colors from './styles/colors';
import halos from './styles/halos';
import mediaQuery from './styles/mediaQuery';
import shadows from './styles/shadows';
import spacing from './styles/spacing';
import transitions from './styles/transitions';
import typography from './styles/typography';
import zIndex from './styles/zIndex';
import * as tokens from './tokens';

export { default as Reset } from './Reset';
export { default as AlloyScopedGlobals } from './AlloyScopedGlobals';
export { getInteractiveStyles } from './styles/helpers';
export * from './styledComponents';

export default {
  name: 'Light',
  borderRadius,
  colors,
  halos,
  mediaQuery,
  shadows,
  spacing,
  tokens,
  transitions,
  typography,
  zIndex,
};
