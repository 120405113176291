import { createGlobalStyle } from 'styled-components';
import { fontFamily, fontWeights, fontSizes, colors } from './lightTheme';

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    -ms-text-size-adjust: 100%; /* stylelint-disable-line */
    -webkit-text-size-adjust: 100%; /* stylelint-disable-line */
  }

  body {
    margin: 0;
    padding: 0;
    font-weight: ${fontWeights.normal};
    font-family: ${fontFamily.artifakt};
    font-size: ${fontSizes.medium};
    color: ${colors.charcoal900};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  optgroup,
  select {
    margin: 0;
  }

  textarea,
  input {
    font-weight: ${fontWeights.normal};
    font-family: ${fontFamily.artifakt};
    font-size: ${fontSizes.medium};

    &,
    &:focus {
      outline: none;
      box-shadow: none;
      margin: 0;
      transition: none;
    }
  }

  a {
    text-decoration: none;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }
`;
