import { ButtonHTMLAttributes } from 'react';

import PropTypes from 'prop-types';

import { StylableComponent } from '@adsk/alloy-react-theme';

export const buttonTypePropType = PropTypes.oneOf<
  ButtonHTMLAttributes<HTMLButtonElement>['type']
>(['button', 'reset', 'submit']);

export const buttonTypeArgType = {
  options: ['button', 'reset', 'submit'],
  control: {
    type: 'select',
  },
};

export type StylableButtonProps = StylableComponent<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>;
