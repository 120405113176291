import { styled, css, boolean, colors, fontWeights } from '@adsk/nirvana-theme';

import { IconButton } from '@adsk/alloy-react-button';

import TextInput from '@adsk/nirvana-text-input';
import { MenuItem } from '@adsk/nirvana-cascade-menu';
import Popover from '@adsk/nirvana-popover';

export const Wrapper = styled.div`
  position: relative;
`;

export const Icon = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

// https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
export const Input = styled(TextInput)`
  && {
    padding-left: 34px;

    ${boolean(
      'clearable',
      css`
        padding-right: 34px;
      `
    )}
  }
`;

export const HighlightItem = styled.span`
  ${boolean(
    'highlighted',
    css`
      font-weight: ${fontWeights.bold};
    `
  )}
`;

export const SuggestionItem = styled(MenuItem)`
  ${MenuItem.Label} {
    overflow: hidden;
  }

  ${boolean(
    'highlighted',
    css`
      background-color: ${colors.charcoal050};
    `
  )}
`;

export const SuggestionMenu = styled(Popover)`
  padding: 2px 0;
`;
