import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

const draw16px =
  'M15,11.39c-.31-.58-1.91-3.38-3.27-5.76-1-1.72-1.84-3.22-2-3.49A2,2,0,0,0,7.92.87,2,2,0,0,0,6.08,2.13L4,5.79c-1.34,2.34-2.87,5-3.18,5.6a2.2,2.2,0,0,0,0,2.18,1.94,1.94,0,0,0,1.68.93H13.37a2,2,0,0,0,1.69-.93A2.2,2.2,0,0,0,15,11.39ZM7.17,5.69a.75.75,0,1,1,1.5,0V8.16a.75.75,0,0,1-1.5,0Zm.75,7.06A1.1,1.1,0,1,1,9,11.65,1.11,1.11,0,0,1,7.92,12.75Z';
const draw24px =
  'M22.34,17.26c-.47-.86-2.79-4.91-4.83-8.49-1.51-2.64-2.85-5-3.08-5.39a2.58,2.58,0,0,0-4.86,0C9.34,3.8,8,6.15,6.48,8.81c-2,3.56-4.34,7.59-4.81,8.45a2.92,2.92,0,0,0,0,2.9,2.51,2.51,0,0,0,2.19,1.21H20.19a2.53,2.53,0,0,0,2.21-1.21A2.94,2.94,0,0,0,22.34,17.26ZM11,7.87a1,1,0,0,1,2,0V13.4a1,1,0,0,1-2,0Zm1,11a1.34,1.34,0,1,1,1.34-1.34A1.34,1.34,0,0,1,12,18.82Z';

const icons: IconVariations = {
  _16: { d: draw16px, viewBox: VIEWBOXES._16 },
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const AlertWarningFilledIcon = forwardRef<
  SVGSVGElement,
  ComponentProps<typeof Icon>
>(generateRenderedIcon(icons, 'alertWarningFilled')) as IconWithRender;

AlertWarningFilledIcon.displayName = 'AlertWarningFilledIcon';
AlertWarningFilledIcon.propTypes = Icon.propTypes;
AlertWarningFilledIcon.renderIcon = (props) => (
  <AlertWarningFilledIcon {...props} />
);
export default AlertWarningFilledIcon;
