import React, { forwardRef, ComponentProps } from 'react';

import Icon, { IconWithRender, generateRenderedIcon } from './Icon';
import { VIEWBOXES } from '../consts';
import { IconVariations } from '../useIconVariation';

// TODO: merge `d` attribute into one for 16px
const draw24px =
  'M21.75,12A9.75,9.75,0,1,1,12,2.25,9.76,9.76,0,0,1,21.75,12Zm-8.33,0,2.82-2.83a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L12,10.59,9.17,7.76a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L10.59,12,7.76,14.83a1,1,0,0,0,1.41,1.42L12,13.42l2.83,2.83a1,1,0,1,0,1.41-1.42Z';

const icons: IconVariations = {
  _24: { d: draw24px, viewBox: VIEWBOXES._24 },
};

const XCircleFilledIcon = forwardRef<
  SVGSVGElement,
  ComponentProps<typeof Icon>
>(generateRenderedIcon(icons, 'xCircleFilled')) as IconWithRender;

XCircleFilledIcon.displayName = 'XCircleFilledIcon';
XCircleFilledIcon.propTypes = Icon.propTypes;
XCircleFilledIcon.renderIcon = (props) => <XCircleFilledIcon {...props} />;
export default XCircleFilledIcon;
