import { styled, css, boolean, colors } from '@adsk/nirvana-theme';
import EllipsisText from '@adsk/nirvana-ellipsis-text';

export const Wrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  height: 36px;
  padding: 0 8px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.charcoal050};
  }

  ${boolean(
    'isActive',
    css`
      background-color: ${colors.charcoal050};
    `
  )}

  ${boolean(
    'disabled',
    css`
      cursor: default;
      opacity: 0.4;

      &:hover {
        background-color: initial;
      }
    `
  )}
`;

export const Label = styled(EllipsisText)`
  flex: auto;
  margin: 0 8px;
`;

export const Divider = styled.li`
  display: block;
  height: 1px;
  margin: 2px 0;
  background-color: ${colors.charcoal100};
`;
