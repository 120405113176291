import React from 'react';
import PropTypes from 'prop-types';

import { ChevronRightIcon } from '@adsk/alloy-react-icon';

import { Wrapper, Label } from './styles';
const MenuItem = React.forwardRef(
  ({ children, value, disabled, path, options, renderIcon, onClick, onHover, ...rest }, forwardedRef) => {
    const handleClick = React.useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        !disabled && onClick && onClick(value, path, options);
      },
      [disabled, value, path, options, onClick]
    );
    const handleHover = React.useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        !disabled && onHover && onHover(value, path, options);
      },
      [disabled, path, value, options, onHover]
    );

    return (
      <Wrapper
        {...rest}
        data-testid={rest['data-testid'] || `menu-item-${value}`}
        ref={forwardedRef}
        disabled={disabled}
        onClick={handleClick}
        onMouseOver={handleHover}
      >
        {renderIcon?.({ style: { marginLeft: '4px', flex: 'none' } })}
        <Label>{children}</Label>
        {options && options.length > 0 && <ChevronRightIcon size={14} />}
      </Wrapper>
    );
  }
);

MenuItem.displayName = 'MenuItem';
MenuItem.Label = Label;

export default MenuItem;

MenuItem.propTypes = {
  /**
   * Label for the option
   */
  children: PropTypes.node,
  /**
   * Value for the option
   */
  value: PropTypes.string.isRequired,
  /**
   * Path for the option
   */
  path: PropTypes.string,
  /**
   * If the option is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If the option is active
   */
  isActive: PropTypes.bool,
  /**
   * An icon node
   */
  renderIcon: PropTypes.func,
  /**
   * Sub menu items
   */
  options: PropTypes.arrayOf(PropTypes.shape(MenuItem.propTypes)),
  /**
   * Callback on item click
   */
  onClick: PropTypes.func,
  /**
   * Callback on item hover
   */
  onHover: PropTypes.func
};
