import { createGlobalStyle } from 'styled-components';

const ArtifaktElement = createGlobalStyle`
  @font-face {
    font-family: 'Artifakt Element';
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Book.eot');
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Book.eot?#iefix') format('embedded-opentype'),
      local('Artifakt Element Book'), local('ArtifaktElement-Book'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Book.woff2') format('woff2'),
      local('Artifakt Element Book'), local('ArtifaktElement-Book'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Book.woff') format('woff'),
      local('Artifakt Element Book'), local('ArtifaktElement-Book'),
      url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Book.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Artifakt Element';
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot');
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot?#iefix') format('embedded-opentype'),
      local('Artifakt Element Medium'), local('ArtifaktElement-Medium'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Medium.woff2') format('woff2'),
      local('Artifakt Element Medium'), local('ArtifaktElement-Medium'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Medium.woff') format('woff'),
      local('Artifakt Element Medium'), local('ArtifaktElement-Medium'),
      url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Artifakt Element';
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot');
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot?#iefix') format('embedded-opentype'),
      local('Artifakt Element Bold'), local('ArtifaktElement-Bold'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2') format('woff2'),
      local('Artifakt Element Bold'), local('ArtifaktElement-Bold'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff') format('woff'),
      local('Artifakt Element Bold'),local('ArtifaktElement-Bold'),
      url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: 'Artifakt Element';
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Black.eot');
    src: url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Black.eot?#iefix') format('embedded-opentype'),
      local('Artifakt Element Black'), local('ArtifaktElement-Black'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Black.woff2') format('woff2'),
      local('Artifakt Element Black'), local('ArtifaktElement-Black'),
      url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Black.woff') format('woff'),
      local('Artifakt Element Black'), local('ArtifaktElement-Black'),
      url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: fallback;
  }
`;

export default ArtifaktElement;
