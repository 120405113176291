import React from 'react';

export const shouldIgnoreClickOutside = (e) => {
  let target = e.target;
  while (!(target.dataset && target.dataset.ignoreClickOutside)) {
    if (target === e.currentTarget || !target.parentNode) return false;
    target = target.parentNode;
  }
  return true;
};

export const useClickOutSide = (handler, refs = [], enabled = true) => {
  React.useEffect(() => {
    if (!enabled) return;

    const listener = (e) => {
      const refsArr = Array.isArray(refs) ? refs : [refs];
      // Do nothing if
      // 1. target element has the data-ignore-click-outside attribute
      // 2. clicking tooltipRef’s element or descendent elements
      if (shouldIgnoreClickOutside(e) || refsArr.some((ref) => ref?.current?.contains(e.target))) {
        return;
      }
      handler(e);
    };

    document.addEventListener('mousedown', listener);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [handler, refs, enabled]);
};
