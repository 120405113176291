import React from 'react';

import PropTypes from 'prop-types';

import { RenderIconType } from '@adsk/alloy-react-icon';
import theme, { stylePropType } from '@adsk/alloy-react-theme';

import BasicButton from './BasicButton';
import { buttonTypePropType, StylableButtonProps } from '../consts';

/**
 * Icon Button.
 */
const IconButton = React.forwardRef<
  HTMLButtonElement,
  StylableButtonProps & {
    renderIcon?: RenderIconType;
    size?: number;
  }
>(({ style, className, children, size = 24, renderIcon, ...props }, ref) => {
  return (
    <BasicButton
      ref={ref}
      className={className}
      style={[
        {
          padding: 0,
          width: size,
          height: size,
          color: theme.colors.charcoal700,
          '&:hover': {
            color: theme.colors.adskBlue500,
          },
          '&:active': {
            color: theme.colors.adskBlue500,
          },
          '&:focus': {
            color: theme.colors.adskBlue700,
          },
          '&[disabled]': {
            color: theme.colors.charcoal500,
            cursor: 'not-allowed',
          },
        },
        style,
      ]}
      {...props}
    >
      {children || renderIcon?.({ size })}
    </BasicButton>
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  /** BasicButton's content */
  children: PropTypes.node,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */
  className: PropTypes.string,
  /** button html type, eg: submit */
  type: buttonTypePropType,
  /** Icon size, passed only when using `iconType` */
  size: PropTypes.number,
  /** Icon type to display on left */
  renderIcon: PropTypes.func,
};

export default IconButton;
