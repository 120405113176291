import {
  CSSProperties,
  DetailedHTMLProps,
  DOMAttributes,
  ElementType,
  HTMLAttributes,
} from 'react';

import PropTypes, { Validator } from 'prop-types';
import { CSSObject, CSSProp } from 'styled-components';

export const asPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.elementType,
]) as Validator<ElementType>;

export const stylePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
  PropTypes.array,
]) as Validator<CSSProp>;

export type StylableComponent<
  Tag,
  Attributes extends DOMAttributes<Tag> = HTMLAttributes<Tag>,
> = Omit<Attributes, 'style' | 'css'> & {
  as?: ElementType;
  style?: CSSProp | null;
};

export type DetailedStylableComponent<
  Attributes extends HTMLAttributes<Tag>,
  Tag,
  DetailedAttributes = DetailedHTMLProps<Attributes, Tag>,
> = Omit<DetailedAttributes, 'style' | 'css'> & {
  as?: ElementType;
  style?: CSSProp | null;
};

/**
 * Type used for render props
 *
 * ```typescript
 * type MyComponentProps = StylableComponent<HTMLDivElement> & {
 * renderBody?: (props: StylableRenderPropType) => ReactElement;
 * }
 * ```
 */
export type StylableRenderPropType = { style: CSSProperties };

const isNotEmpty = (value: string) => !!value && value.trim().length > 0;

const parseStyleString = (styleString: string) =>
  styleString
    .split(';')
    .filter(isNotEmpty)
    .map((cur) => cur.split(':'))
    .reduce(
      (acc, val) => {
        const [key, value] = val;
        const camelCaseKey = key
          .trim()
          .replace(/-./g, (css) => css.toUpperCase()[1]);
        acc[camelCaseKey] = value.trim();
        return acc;
      },
      {} as Record<string, string>,
    );

/**
 * Converts styled-components type of style (array or object) to one compatible with react.
 * This is useful for converting our style before providing it to users through render functions.
 * If a user of ours does not use styled-components it can be difficult for them to apply it
 * without us converting it first.
 *
 * @param style styled-components specific style
 * @returns Regular react-style style object
 */
export const flattenStyle = (style?: CSSProp | null): CSSObject | undefined => {
  if (!style) {
    return undefined;
  }

  if (typeof style === 'string') {
    return parseStyleString(style);
  }

  if (!Array.isArray(style)) {
    return style as CSSObject;
  }

  return style
    .map((x) => flattenStyle(x))
    .reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
};
