export const fontFamily = {
  artifakt: "'Artifakt Element', sans-serif"
};

const palettes = {
  charcoal050: '#f6f6f6',
  charcoal100: '#eeeeee',
  charcoal200: '#dcdcdc',
  charcoal300: '#cccccc',
  charcoal400: '#bbbbbb',
  charcoal500: '#999999',
  charcoal600: '#808080',
  charcoal700: '#666666',
  charcoal800: '#4d4d4d',
  charcoal900: '#3c3c3c',

  blue100: '#cdeaf7',
  blue200: '#9bd5ef',
  blue300: '#6ac0e7',
  blue400: '#38abdf',
  blue500: '#0696d7',
  blue600: '#0686c2',
  blue700: '#006eaf',
  blue800: '#034b6c',
  blue900: '#022c40',

  darkBlue100: '#e1ebf7',
  darkBlue200: '#d1deee',
  darkBlue300: '#a3bcdc',
  darkBlue400: '#749bcb',
  darkBlue500: '#4679b9',
  darkBlue600: '#1858a8',
  darkBlue700: '#12437f',
  darkBlue800: '#0e3666',
  darkBlue900: '#0c2c54',

  turquoise100: '#d6f2ef',
  turquoise200: '#ade4de',
  turquoise300: '#84d7ce',
  turquoise400: '#5bc9bd',
  turquoise500: '#32bcad',
  turquoise600: '#23a597',
  turquoise700: '#268d82',
  turquoise800: '#20756c',
  turquoise900: '#195e57',

  green100: '#e7f2d9',
  green200: '#cfe4b3',
  green300: '#b7d78c',
  green400: '#9fc966',
  green500: '#87b340',
  green600: '#658d30',
  green700: '#5d822c',
  green800: '#4c6b24',
  green900: '#445e20',

  yellowOrange100: '#feecd1',
  yellowOrange200: '#fddaa4',
  yellowOrange300: '#fcc776',
  yellowOrange400: '#fbb549',
  yellowOrange500: '#faa21b',
  yellowOrange600: '#bb7a14',
  yellowOrange700: '#916010',
  yellowOrange800: '#7d510e',
  yellowOrange900: '#523609',

  red100: '#ffeeea',
  red200: '#f8d3d3',
  red300: '#f1a7a7',
  red400: '#eb7a7a',
  red500: '#ec4a41',
  red600: '#dd2222',
  red700: '#a61a1a',
  red800: '#6f1111',
  red900: '#630f0f',

  salmon100: '#ffefec',
  salmon200: '#fcded9',
  salmon300: '#ffbeb3',
  salmon400: '#ff9582',
  salmon500: '#f26a52',
  salmon600: '#db5942',
  salmon700: '#a84a39',
  salmon800: '#853729',
  salmon900: '#66251a',

  slate100: '#e9f0f7',
  slate200: '#dce7f3',
  slate300: '#c1cedc',
  slate400: '#a7bacf',
  slate500: '#7993b0',
  slate600: '#6784a6',
  slate700: '#4b6b8f',
  slate800: '#354d67',
  slate900: '#2c3e53'
};

export const colors = {
  ...palettes,
  // levels
  primary: palettes.blue500,
  secondary: palettes.charcoal500,
  success: palettes.green500,
  info: palettes.turquoise500,
  warning: palettes.yellowOrange500,
  error: palettes.red500,
  dark: palettes.charcoal900,
  light: palettes.charcoal050,

  // colors
  white: '#ffffff',
  black: palettes.charcoal900,
  blue: palettes.blue500,
  gray: palettes.charcoal500,
  green: palettes.green500,
  yellow: palettes.yellowOrange500,
  red: palettes.red500
};

export const fontWeights = {
  normal: 500,
  semiBold: 600,
  bold: 700,
  xBold: 800
};

export const fontSizes = {
  xxxLarge: '30px',
  xxLarge: '22px',
  xLarge: '18px',
  large: '16px',
  medium: '14px',
  small: '12px',
  xSmall: '11px',
  xxSmall: '10px'
};

export const margins = {
  xxSmall: '4px',
  xSmall: '8px',
  small: '12px',
  medium: '16px',
  large: '24px',
  xLarge: '32px',
  xxLarge: '48px'
};

export const paddings = {
  xxSmall: '4px',
  xSmall: '8px',
  small: '12px',
  medium: '16px',
  large: '24px',
  xLarge: '32px',
  xxLarge: '48px'
};

export const shadows = {
  low: '0px 1px 6px rgba(12, 44, 84, 0.12)',
  high: '0px 4px 14px rgba(12, 44, 84, 0.15), 0px 0px 2px rgba(12, 44, 84, 0.25)'
};
