import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { stylePropType } from '@adsk/alloy-react-theme';

import { buttonTypePropType, StylableButtonProps } from '../consts';

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
`;

/**
 * Basic Button.
 */
const BasicButton = React.forwardRef<HTMLButtonElement, StylableButtonProps>(
  (
    {
      style,
      className,
      children,
      disabled,
      onClick,
      type = 'button',
      ...props
    },
    ref,
  ) => (
    <StyledButton
      ref={ref}
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      css={style}
      {...props}
    >
      {children}
    </StyledButton>
  ),
);

BasicButton.displayName = 'BasicButton';

BasicButton.propTypes = {
  /** BasicButton's content */
  children: PropTypes.node,
  /** Styles applied to the root element */
  style: stylePropType,
  /** Class applied to the root element */ className: PropTypes.string,
  /** button html type, eg: submit */
  type: buttonTypePropType,
  /** Disable button functionality */
  disabled: PropTypes.bool,
  /** handle onclick event for button */
  onClick: PropTypes.func,
};

export default BasicButton;
