import React from 'react';
import PropTypes from 'prop-types';

import { MagnifyingGlassIcon, XCircleFilledIcon } from '@adsk/alloy-react-icon';
import { useForwardedRef } from '@adsk/alloy-react-helpers';

import Field from '@adsk/nirvana-field';
import TextInput from '@adsk/nirvana-text-input';
import { Divider } from '@adsk/nirvana-cascade-menu';
import EllipsisText from '@adsk/nirvana-ellipsis-text';
import { margins } from '@adsk/nirvana-theme';

import { Wrapper, Input, HighlightItem, SuggestionItem, SuggestionMenu, Icon } from './styles';

/**
 * Search
 */
export const Search = React.forwardRef(
  ({ disabled, value, onSearch, onClear, onKeyDown, style, className, ...rest }, ref) => {
    const inputRef = useForwardedRef(ref);
    const handleSearch = (event) => {
      inputRef.current?.focus();
      onSearch && onSearch(value, event);
    };

    const handleKeyDown = (event) => {
      if (onKeyDown) {
        onKeyDown(event);
        return;
      }
      if (event && event.keyCode === 13) handleSearch(event);
    };

    return (
      <Wrapper style={style} className={className}>
        <Icon
          data-testid="search-button"
          renderIcon={MagnifyingGlassIcon.renderIcon}
          style={{ left: margins.xSmall }}
          disabled={disabled}
          size={20}
          onClick={handleSearch}
        />
        <Input
          {...rest}
          ref={inputRef}
          value={value}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          clearable={!!value}
        />
        {!!value && (
          <Icon
            data-testid="search-clear-button"
            renderIcon={XCircleFilledIcon.renderIcon}
            style={{ right: margins.xSmall }}
            disabled={disabled}
            size={20}
            onClick={onClear}
          />
        )}
      </Wrapper>
    );
  }
);

Search.displayName = 'Search';

Search.propTypes = {
  ...TextInput.propTypes,
  /**
   * The callback of the input change event. With the shape of (value, event) => any
   */
  onSearch: PropTypes.func,
  /**
   * The callback of the input change event. With the shape of (event) => any
   */
  onClear: PropTypes.func,
  /**
   * The callback of the input keydown event. With the shape of (event) => any
   */
  onKeyDown: PropTypes.func
};

const SuggestionLabel = ({ suggestion = '', keyword = '', maxMatchLength = 255 }) => {
  const matchWord = keyword.slice(0, maxMatchLength);
  const reg = new RegExp(`(${matchWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'ig');

  return (
    <EllipsisText>
      {suggestion.split(reg).map((partial, index) => (
        <HighlightItem key={index} highlighted={partial.toLowerCase() === matchWord.toLowerCase()}>
          {partial}
        </HighlightItem>
      ))}
    </EllipsisText>
  );
};

SuggestionLabel.propTypes = {
  /**
   * The suggestion label.
   */
  suggestion: PropTypes.string,
  /**
   * The keyword to be highlighted in the suggestion label.
   */
  keyword: PropTypes.string,
  /**
   * The max match length of the suggestion label. Default to 255.
   * Once the match length is reached, the rest of the label will not be highlighted.
   * This is to avoid regex error when the keyword is too long.
   * A sample error can be found at https://app.datadoghq.com/rum/error-tracking/issue/fc6e14a0-1800-11ee-8e44-da7ad0900002
   */
  maxMatchLength: PropTypes.number
};

/**
 * SearchField
 */
const SearchField = React.forwardRef(
  (
    {
      value,
      label,
      width,
      required,
      direction,
      errorMessage,
      errorPosition,
      invalid,
      style,
      className,
      suggestions = [],
      maxMatchLength,
      footer,
      onSelect,
      onSearch,
      onKeyDown,
      container,
      ...rest
    },
    forwardedRef
  ) => {
    const [showMenu, setShowMenu] = React.useState(false);
    const [highlightIndex, setHighlightIndex] = React.useState(undefined);
    const targetRef = React.useRef(null);
    const setTargetRef = React.useCallback(
      (ref) => {
        targetRef.current = ref;
        if (forwardedRef) {
          typeof forwardedRef === 'function' ? forwardedRef(ref) : (forwardedRef.current = ref);
        }
      },
      [targetRef, forwardedRef]
    );

    React.useEffect(() => {
      setHighlightIndex(undefined);
    }, [suggestions]);

    const menuWidth = targetRef.current ? targetRef.current.offsetWidth : 0;

    const handleSearch = (val, e) => {
      onSearch(val, e);
      // hide menu and lose focus
      setShowMenu(false);
      targetRef.current && targetRef.current.blur();
    };

    const handleSelect = (suggestion, e) => {
      onSelect && onSelect(suggestion, e);
      setShowMenu(false);
      targetRef.current && targetRef.current.blur();
    };

    const handleKeyDown = (event) => {
      // Handle Enter key
      if (event.keyCode === 13) {
        highlightIndex !== undefined ? handleSelect(suggestions[highlightIndex], event) : handleSearch(value, event);
        return;
      }

      // Return if no suggestions
      if (suggestions.length === 0 || !showMenu) onKeyDown && onKeyDown(event);

      // Handle Key Down / Up for suggestions
      switch (event.keyCode) {
        case 40:
          // Key Down
          setHighlightIndex(
            highlightIndex === undefined ? 0 : highlightIndex + 1 < suggestions.length ? highlightIndex + 1 : 0
          );
          break;
        case 38:
          // Key Up
          setHighlightIndex(
            highlightIndex === undefined
              ? suggestions.length - 1
              : highlightIndex > 0
              ? highlightIndex - 1
              : suggestions.length - 1
          );
          break;
        default:
          onKeyDown && onKeyDown(event);
          break;
      }
    };

    return (
      <Field
        style={style}
        className={className}
        label={label}
        width={width}
        required={required}
        direction={direction}
        errorMessage={errorMessage}
        errorPosition={errorPosition}
      >
        <Search
          {...rest}
          ref={setTargetRef}
          invalid={!!(invalid || errorMessage)}
          value={value}
          onFocus={(e) => {
            setShowMenu(true);
            rest.onFocus && rest.onFocus(e);
          }}
          onSearch={handleSearch}
          onKeyDown={handleKeyDown}
        />
        <SuggestionMenu
          container={container}
          show={showMenu && (suggestions.length > 0 || !!footer)}
          hideArrow
          trigger={false}
          placement="bottom-start"
          target={targetRef.current}
          style={{ width: menuWidth, maxWidth: menuWidth }}
          onHide={(e) => {
            if (e.target !== targetRef.current) {
              setShowMenu(false);
            }
          }}
        >
          <>
            {suggestions.map((suggestion, idx) => (
              <SuggestionItem
                key={`${idx}-${suggestion}`}
                renderIcon={MagnifyingGlassIcon.renderIcon}
                highlighted={idx === highlightIndex}
                value={suggestion}
                onClick={(e) => handleSelect(suggestion, e)}
              >
                <SuggestionLabel suggestion={suggestion} keyword={value} maxMatchLength={maxMatchLength} />
              </SuggestionItem>
            ))}
            {suggestions.length > 0 && footer && <Divider />}
            {footer}
          </>
        </SuggestionMenu>
      </Field>
    );
  }
);

SearchField.displayName = 'SearchField';
SearchField.Label = Field.Label;
SearchField.ErrorMessage = Field.ErrorMessage;
SearchField.ErrorIcon = Field.ErrorIcon;
SearchField.SuggestionMenu = SuggestionMenu;
SearchField.SuggestionItem = SuggestionItem;

SearchField.propTypes = {
  ...Field.propTypes,
  ...Search.propTypes,
  /**
   * The `container` will have the Popover appended to it.
   */
  container: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Array of the suggestion strings
   */
  suggestions: PropTypes.arrayOf(PropTypes.string),
  /**
   * The max match length of the suggestion label
   */
  maxMatchLength: PropTypes.number,
  /**
   * Suggestion dropdown footer
   */
  footer: PropTypes.node,
  /**
   * Callback when suggestion item selected
   */
  onSelect: PropTypes.func
};

export default SearchField;
